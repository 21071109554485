<template>
  <div class="left-sidebar">
    <div class="left-sidebar__close d-min-1200-none">
      <font-awesome-icon icon="arrow-left" @click="closeSidebar" />
    </div>

    <div class="left-sidebar__list">
      <LabelButtonCard
        :list="subModules"
        :base-card="false"
        :searchable="false"
        :labels="labels"
        :displayReviewStatus="true"
        height="100%"
        list-height="calc( 100% - 93px )"
        :search-placeholder="labels.write_here_to_search"
      />
    </div>

    <div
      v-if="canCreateSubPackage"
      class="left-sidebar__footer"
      @click="openPopup"
    >
      {{ labels.new_sub_module }}
    </div>
  </div>
</template>

<script>
import LabelButtonCard from "@/components/Cards/LabelButtonCard";

import { mapState, mapActions } from "vuex";

export default {
  name: "LeftSideBar",
  components: {
    LabelButtonCard,
  },
  props: {
    closeSidebar: {
      type: Function,
      default: () => {},
    },
    labels: {
      type: Object,
      default: () => {},
    },
    modulePageData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      subModules: [],
    };
  },
  computed: {
    ...mapState("helper", {
      user_id: "user_id",
      admin_roles: "admin_roles",
    }),
    ...mapState("_module", {
      singleData: "singleData",
    }),
    canCreateSubPackage() {
      if (
        this.modulePageData.isAuthor ||
        this.modulePageData.hasRole("super") ||
        this.modulePageData.hasRole("creator")
      )
        return true;

      return false;
    },
  },
  methods: {
    ...mapActions("notification", {
      addNotification: "addNotification",
    }),
    ...mapActions("_module", {
      getModules: "getList",
    }),
    openPopup() {
      if (this.canCreateSubPackage) {
        this.openCreateModulePopup();
      }
    },
    openCreateModulePopup() {
      if (this.$route.name !== "create-sub-module")
        this.$router.push({ name: "create-sub-module" });
    },
    getSubModulesMt(page) {
      let data = {
        page,
        parent: this.$route.params.id,
      };

      this.getModules(data)
        .then((res) => {
          if (res.success) {
            this.subModules = [];
            res.data.data.forEach((item) => {
              this.subModules.push({
                _id: item._id,
                label: item.title,
                review: item.review,
                actions: [
                  {
                    type: "icon",
                    icon: "custom-external-link",
                    link: this.$router.resolve({
                      name: "module-editor",
                      params: { id: item._id },
                    }).href,
                  },
                ],
              });
            });
          } else if (res.error) {
            this.addNotification({
              variant: "danger",
              msg: res.error,
              labels: this.labels,
            });
          }
        })
        .catch((err) => console.log(err));
    },
  },
  mounted() {
    this.getSubModulesMt(1);
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
.left-sidebar {
  &__close {
    padding: 15px 25px 0;
    svg {
      display: block;
      text-align: center;
      margin: 0 0 0 auto;
      padding: 7px;
      width: 40px;
      height: 30px;
      border-radius: 5px;
      background-color: $purple;
      color: $white;
      transition: all 0.3s ease;
      box-shadow: none;
      cursor: pointer;

      &:hover {
        box-shadow: 0 0 6px 2px rgba(93, 52, 206, 0.5);
      }
    }
  }

  &__list {
    height: calc(100% - 50px);

    @media (max-width: 1200px) {
      height: calc(100% - 95px);
    }
  }

  &__footer {
    text-transform: uppercase;
    background-color: $background;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 50px;
    line-height: 50px;
    text-align: center;
    font-size: 0.875rem;
    font-weight: 600;
    color: $dark_text;
    cursor: pointer;
  }
}
</style>
