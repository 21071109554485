<template>
  <div class="editor">
    <LeftSideBar
      ref="editor-left-sidebar"
      :labels="labels"
      :close-sidebar="contentSidebarData.leftSidebarAction"
      :class="[
        'editor__left-sidebar',
        { active: contentSidebarData.leftStatus },
      ]"
      :module-page-data="modulePageData"
    />
    <div class="editor__content">
      <h4 class="mb-1 text-center" v-if="modulePageData.contentKind == 1">
        Linked content
      </h4>
      <ReviewInProgressBlock
        v-if="
          ['author', 'reviewer'].includes(editorMode) &&
          (modulePageData.isReviewer || modulePageData.isAuthor) &&
          waitingContentResponse &&
          modulePageData.isInReview
        "
        :text="labels.review_in_progress"
        :button-labels="{ notes: contentNotesLabel, add_note: labels.add_note }"
        :backgroundColor="`${colorsHtml.background_2}`"
        :reviewNotes="goToReviewNotes"
      />
      <XFormActions
        v-if="['history', 'pending'].includes(modulePageData.status)"
        class="mb-4"
        :data="XFormActionsData"
        @changed="dropdownChanged"
      />
      <XFormSortable
        class="mb-4"
        :class="{ 'content-disabled': !contentEditable }"
        v-for="(block, aIdx) in testModuleContent"
        :key="`${aIdx}_${contentUpdateKey}`"
        :sorting-data="{ index: aIdx, amount: testModuleContent.length }"
        :data="block"
        :max-indent="getMaxIndent(testModuleContent[aIdx - 1])"
        :header-actions="sortingBlockActions"
        :settings="settings"
        :extra-key="modulePageData.status"
        @changed="updateContentBlock"
        @addVideo="addFiles"
        @getMedia="getMedia"
      />
      <div
        v-if="contentEditable && modulePageData.isModuleContentPresent"
        class="buttons-wrapper"
      >
        <div class="buttons">
          <Button
            variant="btn-outline-purple"
            :label="labels.text"
            @click="addBlock(0)"
          />
          <Button
            variant="btn-outline-purple"
            class="ml-4"
            :label="labels.definition"
            @click="addBlock(1)"
          />
        </div>
        <div class="buttons mt-4">
          <button class="btn btn-outline-success" @click="addBlock(2)">
            {{ labels.color_field }}
          </button>
          <button class="btn btn-outline-success ml-4" @click="addBlock(3)">
            {{ labels.video }}
          </button>
          <button class="btn btn-outline-success ml-4" @click="addBlock(4)">
            {{ labels.image }}
          </button>
        </div>
      </div>
      <div v-if="modulePageData.cancelReviewPossible" class="buttons-wrapper">
        <Button
          variant="btn-outline-purple"
          :label="labels.cancel_review"
          @click="cancelReviewMD"
        />
      </div>
    </div>

    <RightSideBar
      ref="editor-right-sidebar"
      :labels="labels"
      :close-sidebar="contentSidebarData.rightSidebarAction"
      :class="[
        'editor__right-sidebar',
        { active: contentSidebarData.rightStatus },
      ]"
      :module-page-data="modulePageData"
    />

    <router-view
      name="create_card"
      modal-id="create-card"
      kind="card"
      :route-back="{ name: 'module-editor' }"
      :title="labels.create_new_card"
      :custom-fields="cardCustomFields"
      :required-data-set="[
        'title',
        'content_0',
        'content_1',
        'kind',
        'referer',
      ]"
      :data-set-values="{ kind: 0, referer: this.moduleId }"
      @entity-created="cardCreated"
    />

    <router-view
      name="create_definition"
      modal-id="create-definiton"
      kind="card"
      :route-back="{ name: 'module-editor' }"
      :title="labels.create_new_definition"
      :custom-fields="definitionCustomFields"
      :required-data-set="[
        'title',
        'content_0',
        'content_1',
        'kind',
        'referer',
      ]"
      :data-set-values="{ kind: 1, referer: this.moduleId }"
      @entity-created="cardCreated"
    />

    <router-view
      name="content_notes"
      modal-id="content_notes"
      kind="notes"
      :route-back="{ name: 'module-editor' }"
      :title="labels.add_note"
      :title-placeholder="labels.title"
      :text-placeholder="labels.description"
      :required-fields="['title', 'text']"
      :required-data-set="['title', 'text']"
      @entity-created="addNewNote"
    />

    <router-view
      name="create_sub_module"
      modal-id="create-sub-module"
      :modulePageData="modulePageData"
      @saved="subModuleCreated"
    />

    <router-view
      name="history"
      modal-id="history"
      :route-back="{ name: 'module-editor' }"
    />
    <router-view
      name="request"
      modal-id="request"
      :route-back="{ name: 'module-editor' }"
    />
    <router-view
      name="search"
      modal-id="search"
      :route-back="{ name: 'module-editor' }"
    />
  </div>
</template>

<script>
import LeftSideBar from "@/views/Dashboard/Module/Editor/LeftSideBar.vue";
import RightSideBar from "@/views/Dashboard/Module/Editor/RightSideBar.vue";
import XFormSortable from "@/components/Forms/XFormSortable";
import XFormActions from "@/components/Forms/XFormActions";
import Button from "@/components/Buttons/Button.vue";
import ReviewInProgressBlock from "@/components/Blocks/ReviewInProgressBlock.vue";
import colorsHtml from "/colors.config.json";
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";

export default {
  name: "Editor",
  components: {
    LeftSideBar,
    RightSideBar,
    XFormSortable,
    XFormActions,
    Button,
    ReviewInProgressBlock,
  },
  props: {
    contentSidebarData: {
      type: Object,
      required: true,
    },
    modulePageData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      colorsHtml: colorsHtml,
      updateKey: Date.now(),
      sortingBlockActions: [
        { icon: "chevron-up", action: this.sortUpMethod },
        { icon: "chevron-down", action: this.sortDownMethod },
        { icon: "custom-trash", action: this.removeBlock },
      ],
    };
  },
  computed: {
    ...mapGetters("helper", ["labels"]),
    ...mapState("helper", {
      clipboard: "clipboard",
      user_id: "user_id",
      admin_roles: "admin_roles",
    }),
    ...mapState("card", {
      cardList: "cardList",
      cardsMap: "cardsMap",
    }),
    ...mapState("_module", {
      stateSingleData: "stateSingleData",
      moduleContent: "moduleContent",
      editorMode: "editorMode",
      waitingContentResponse: "waitingContentResponse",
      singleData: "singleData",
      contentUpdateKey: "contentUpdateKey"
    }),
    countChanges() {
      return this.$store.getters["_module/countContentChanges"];
    },
    settings() {
      return {
        type_key: "kind",
        value_key: "data",
        text_editor: { type: 0 },
        color_field: { type: 2 },
        video: { type: 3 },
        image: { type: 4 },
        definition: {
          type: 1,
          label: this.labels.select_definition,
          remove: (index) => this.updateContentBlock({ data: "", index }),
          pastBtn: {
            variant: "btn-green",
            label: this.labels.paste_definition,
            action: this.pastDefinition,
            show: this.clipboard,
          },
        },
      };
    },
    cardCustomFields() {
      return [
        {
          type: "text",
          name: "title",
          label: this.labels.title,
          class: "mb-4",
        },
        {
          type: "textarea",
          name: "content_0",
          label: this.labels.question,
          class: "mb-4",
          validationName: this.labels.question,
        },
        {
          type: "textarea",
          name: "content_1",
          label: this.labels.answer,
          validationName: this.labels.answer,
        },
      ];
    },
    definitionCustomFields() {
      return [
        {
          type: "text",
          name: "title",
          label: this.labels.title,
          class: "mb-4",
        },
        {
          type: "text",
          name: "content_0",
          label: this.labels.definition_title,
          class: "mb-4",
          validationName: this.labels.definition_title,
        },
        {
          type: "textarea",
          name: "content_1",
          label: this.labels.definition,
          validationName: this.labels.definition,
        },
      ];
    },
    XFormActionsData() {
      return {
        dropdownList: {
          1: "20.12.2021 - 19:20 by user X",
          2: "20.12.2021 - 19:20 by user Y",
        },
        btns: [
          {
            label: this.labels.notes,
            variant: "btn-orange",
            action: () => console.log("Notes action"),
          },
          {
            label: this.labels.revert,
            variant: "btn-green",
            action: () => console.log("Revert action"),
          },
        ],
      };
    },
    videoData() {
      return {
        labels: {
          upload: this.labels.upload,
          upload_video: this.labels.upload_video,
          select: this.labels.select,
          select_video: this.labels.select_video,
        },
        media: "",
        link: "",
        title: "",
        _id: 0,
        action: () => this.deleteMDMedia(),
      };
    },
    imageData() {
      return {
        labels: {
          upload: this.labels.upload,
          upload_image: this.labels.upload_image,
          select: this.labels.select,
          select_image: this.labels.select_image,
        },
        media: "",
        link: "",
        title: "",
        _id: 0,
        action: () => this.deleteMDMedia(),
      };
    },
    testModuleContent() {
      let output = [];
      let index = 0;
      
      this.moduleContent.forEach((item) => {
        if (item.kind === 1 && this.cardsMap[item.data]) {
          output.push({
            ...item,
            title: this.cardsMap[item.data].title,
          });
        } else if (item.kind === 3) {
          item.data.action = (index) =>
            this.deleteMDMedia(item.data.video, "video", index);
          output.push(item);
        } else if (item.kind === 0) {
          item.editorProps = {
            hideBgColorPicker: true,
            hideColorPicker: true,
            forceTextColor: this.presetTextColor(),
            aiPopupId: ++index
          };
          output.push(item);
        } else if (item.kind === 2) {
          item.editorProps = {
            hideBgColorPicker: true,
            hideColorPicker: true,
            forceTextColor: this.presetTextColor(),
          };
          output.push(item);
        } else output.push(item);
      });

      return output;
    },
    moduleId() {
      return this.$route.params.id;
    },
    contentEditable() {
      // Case for author editor mode
      if (
        this.editorMode === "author" &&
        this.modulePageData.isAuthor &&
        !this.modulePageData.isReviewRequested &&
        this.modulePageData.status === "change" &&
        ![-1, 1].includes(this.modulePageData.contentKind)
      )
        return true;

      // Case for reviewer editor mode
      if (
        this.editorMode === "reviewer" &&
        this.modulePageData.isReviewer &&
        this.modulePageData.isInReview &&
        this.modulePageData.status === "change" &&
        ![-1, 1].includes(this.modulePageData.contentKind)
      )
        return true;

      // For other cases content is not supposed to be editable
      return false;
    },
    contentNotesLabel() {
      return this.singleData?.content?.notes?.length ? `${this.labels.notes} (${this.singleData.content.notes.length})` : this.labels.notes;
    }
  },
  methods: {
    ...mapActions("notification", {
      addNotification: "addNotification",
    }),
    ...mapActions("_module", {
      getModule: "getSingle",
      rejectReview: "rejectReview",
    }),
    ...mapMutations("helper", {
      clearClipboard: "clearClipboard",
    }),
    ...mapMutations("_module", {
      addContentBlock: "addContentBlock",
      removeContentBlock: "removeContentBlock",
      updateContentBlock: "updateContentBlock",
    }),
    ...mapActions("_module", {
      addMedia: "addMedia",
      deleteMedia: "deleteMedia",
      reviewApprove: "reviewApprove",
      update: "update",
    }),
    getMaxIndent(data) {
      if (
        data !== undefined &&
        data.kind === 0 &&
        data.data &&
        data.data.indent !== undefined
      ) {
        return data.data.indent;
      }
      return 0;
    },
    pastDefinition(index) {
      // this.updateContentBlock( { data: this.clipboard, index } );
      // this.clearClipboard();
      console.log("Clipboard:", this.clipboard);
      console.log("Index:", index);
    },
    sortDownMethod(index) {
      this.$sortDown(this.$store.state._module.moduleContent, index, this.$set);
    },
    sortUpMethod(index) {
      this.$sortUp(this.$store.state._module.moduleContent, index, this.$set);
    },
    addBlock(kind) {
      let data = "";
      if (kind === 0) data = { text: "", indent: 0 };
      if (kind === 2) data = { text: "", bgColor: "rgba(255,255,255,0)" };
      if (kind === 3) data = this.videoData;
      if (kind === 4) data = this.imageData;
      this.addContentBlock({ kind, data });
      this.updateKey = Date.now();
    },
    removeBlock(index) {
      if (this.moduleContent[index].data.action) {
        this.moduleContent[index].data.action(index);
      }
      this.removeContentBlock(index);
    },
    dropdownChanged(value) {
      console.log(value);
    },
    subModuleCreated() {
      this.$refs["editor-left-sidebar"].getSubModulesMt(1);
    },
    cardCreated() {
      this.$refs["editor-right-sidebar"].getCardsMt(1);
    },
    addFiles(files, filesType, index) {
      console.log("Got files:", files);
      console.log("Got files type:", filesType);
      console.log("Got index:", index);
    },
    deleteMDMedia() {
      // let data = {
      //     id: this.stateSingleData._id,
      //     media: media,
      //     type: filesType
      // }
      // this.deleteMedia(data).then(res => {
      //     if (res.success) {
      //         if (filesType === 'video') this.updateVideos();
      //         // if (filesType === 'audio') this.updateAudios();
      //         if (this.moduleContent[index]) this.updateContentBlock( { data: this.videoData, index: index } );
      //         this.addNotification( { variant: 'success', msg: ['deleted'], labels: this.labels });
      //     }
      // });
    },
    updateVideos() {
      this.videos.videos = [];

      if (this.stateSingleData.videos) {
        this.stateSingleData.videos.forEach((video) => {
          this.videos.videos.push(video);
        });
      }

      this.updateKey = Date.now();
    },
    generateFileTitle(fileName) {
      return fileName.replace(/\.[^/.]+$/, "");
    },
    cancelReviewMD() {
      this.rejectReview({ id: this.stateSingleData._id })
        .then((res) => {
          if (res.success) {
            this.addNotification({
              variant: "success",
              msg: res.msg,
              labels: this.labels,
            });
          } else if (res.error) {
            this.addNotification({
              variant: "danger",
              msg: res.error,
              labels: this.labels,
            });
          }
        })
        .catch((err) => console.log(err));
    },
    goToReviewNotes() {
      if (this.$route.name !== "module-content-notes") {
        this.$router.push({
          name: "module-content-notes",
          query: { page: this.$route.query.page },
        });
      }
    },
    updateModule() {
      let data = {
        id: this.$route.params.id,
        payload: {}, // need add right data
      };

      this.update(data)
        .then((res) => {
          if (res.success) {
            console.log(res);
            this.addNotification({
              variant: "success",
              msg: res.msg,
              labels: this.labels,
            });
          } else if (res.error) {
            this.addNotification({
              variant: "danger",
              msg: res.error,
              labels: this.labels,
            });
          }
        })
        .catch((err) => console.log(err));
    },
    approveReview() {
      let data = {
        // need add right data
        id: "",
        content_id: "",
      };

      this.reviewApprove(data)
        .then((res) => {
          if (res.success) {
            this.addNotification({
              variant: "success",
              msg: res.msg,
              labels: this.labels,
            });
          } else if (res.error) {
            this.addNotification({
              variant: "danger",
              msg: res.error,
              labels: this.labels,
            });
          }
        })
        .catch((err) => console.log(err));
    },
    addNewNote() {
      console.log("addNewNote");
    },
    presetTextColor() {
      if (this.editorMode === "reviewer" && this.modulePageData.isInReview)
        return "#ff0000";

      if (this.editorMode === "author" && this.modulePageData.isInReview)
        return "#00b3ff";

      return `${this.colorsHtml.black}`;
    },
    getMedia(mediaObj, sectionSortingId) {
      const currentContent = { ...this.moduleContent[sectionSortingId] };

      this.updateContentBlock({
        data: {
          ...currentContent.data,
          ...{
            _id: mediaObj._id,
            title: mediaObj.title,
            link: mediaObj.link,
            media: mediaObj.media,
          },
        },
        index: sectionSortingId,
      });
    },
  },
  watch: {
    // editorMode: {
    // 	handler(value) {
    // 		if(value === 'author') {
    //             this.$root.$refs.modulePageContainer.getModuleMt();
    //         }
    // 	}
    // },
    // status: {
    // 	handler() {
    // 		this.$root.$refs.modulePageContainer.getModuleMt();
    // 	}
    // }
  },
  mounted() {
    // this.updateVideos();
    // this.$root.$refs.modulePageContainer.getModuleMt();
  },
  beforeRouteLeave(to, from, next) {
    if (!this.countChanges.saveBtnDisabled) {
      const answer = window.confirm(
        this.labels.do_you_really_want_to_leave_you_have_unsaved_changes
      );
      if (answer) {
        // RESET CHANGES
        this.$root.$refs.modulePageContainer.getModuleMt();
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
.editor {
  position: relative;
  display: flex;

  .content-disabled {
    opacity: 0.3;
    pointer-events: none;
  }

  &__content {
    flex: 1 1 auto;
    padding: 25px;

    .buttons-wrapper {
      display: flex;
      align-items: center;
      flex-direction: column;
      &:nth-last-child(1) {
        margin-top: 24px;
      }

      .buttons {
        // width: 250px;
        display: flex;
        justify-content: space-around;
      }
    }
  }

  &__left-sidebar,
  &__right-sidebar {
    flex: 0 0 315px;
    position: sticky;
    top: 110px;
    height: calc(100vh - 111px);
    min-width: 315px;
    border-right: 1px solid $light_gray;
    background-color: $white;
  }

  &__left-sidebar {
    @media (max-width: 1200px) {
      position: fixed;
      left: -100%;
      top: 0;
      z-index: 1030;
      height: 100vh;

      &.active {
        left: 0px;
        height: calc(100vh - 74px);

        @media (min-width: 768px) {
          left: 50px;
          height: 100vh;
        }

        @media (min-width: 1200px) {
          left: 310px;
        }

        @media (min-width: 1600px) {
          left: 340px;
        }
      }
    }
  }

  &__right-sidebar {
    border-left: 1px solid $light_gray;

    @media (max-width: 992px) {
      position: fixed;
      left: -100%;
      top: 0;
      z-index: 1030;
      height: 100vh;

      &.active {
        left: 0px;
        height: calc(100vh - 74px);

        @media (min-width: 768px) {
          left: 50px;
          height: 100vh;
        }

        @media (min-width: 1200px) {
          left: 310px;
        }

        @media (min-width: 1600px) {
          left: 340px;
        }
      }
    }
  }
}
</style>
