<template>
  <SortableBlock
    class="x-form"
    :style="styles"
    :header-actions="headerActions"
    :sorting-data="sortingData"
  >
    <template slot="header">
      <div class="x-form__header">
        <div class="indent-actions" v-if="checkType('text_editor')">
          <font-awesome-icon
            v-if="
              data[settings.value_key].indent < 10 &&
              maxIndent >= data[settings.value_key].indent
            "
            class="mr-3"
            icon="custom-indent-right"
            @click="
              dataChanged(
                {
                  text: data[settings.value_key].text,
                  indent: ++data[settings.value_key].indent,
                },
                'text_editor'
              )
            "
          />
          <font-awesome-icon
            v-if="data[settings.value_key].indent > 0"
            class="mr-3"
            icon="custom-indent-left"
            @click="
              dataChanged(
                {
                  text: data[settings.value_key].text,
                  indent: --data[settings.value_key].indent,
                },
                'text_editor'
              )
            "
          />
        </div>
      </div>
    </template>

    <template slot="content">
      <div class="x-form__content">
        <MediaLibrary
          v-if="showPopup"
          modal-id="xform-sotrable-media-library"
          :type="mediaLibraryType"
          custom-class="gray-header"
          v-bind:show-popup="showPopup"
          @get-media="getMedia"
          @close="onClose"
        />

        <div class="text-editor" v-if="checkType('color_field')">
          <FormulateInput
            type="text-editor"
            name="text-editor"
            v-model="data[settings.value_key].text"
            :extra-configs="data.editorProps"
            @changed="
              dataChanged({ text: $event, bgColor: hexToRgba }, 'color_field')
            "
          />
        </div>

        <div class="text-editor" v-if="checkType('text_editor')">
          <!--
                    There is a problem with @changed="dataChanged( { text: $event, indent: data[settings.value_key].indent }, 'text_editor' )"
                    It takes huge amount of resourses and because of it rich text editor gets remounted all the time, it may not be the source of the problem but without it it starts to work normal.
                    
                    <FormulateInput
                        type="text-editor"
                        name="text-editor"
                        v-model="data[settings.value_key].text"
                        @changed="dataChanged( { text: $event, indent: data[settings.value_key].indent }, 'text_editor' )"
                        :extra-configs="data.editorProps"
                    />-->
          <FormulateInput
            :key="extraKey + data._id"
            type="text-editor"
            name="text-editor"
            v-model="data[settings.value_key].text"
            :extra-configs="data.editorProps"
          />
        </div>

        <div class="definition" v-if="checkType('definition')">
          <h6 class="mb-4 text-center">{{ settings.definition.label }}</h6>
          <Button
            class="w-100 mb-4"
            v-if="settings.definition.pastBtn.show"
            :variant="settings.definition.pastBtn.variant"
            :label="settings.definition.pastBtn.label"
            @click="pastDefinition"
          />
          <div class="value">
            <FormulateInput
              type="text"
              name="text"
              disabled
              :key="data[settings.value_key]"
              :value="data[settings.value_key]"
              :hidden="true"
            />
            <FormulateInput
              type="text"
              name="text"
              disabled
              :key="`${data[settings.value_key]}-title`"
              :value="data.title"
            />

            <font-awesome-icon
              v-show="data[settings.value_key]"
              class="times-circle"
              icon="custom-times-circle"
              @click="settings.definition.remove(sortingData.index)"
            />
          </div>
        </div>

        <div class="video" v-if="checkType('video')">
          <VideoInputPlayer
            :button-label="data[settings.value_key].labels.upload_video"
            margin="-25px"
            :is-multiple="false"
            :key="data[settings.value_key]._id"
            :text="data[settings.value_key].title"
            :video="data[settings.value_key].media"
            :link="data[settings.value_key].link"
            :action="false"
          >
            <Button
              class="btn-slateblue"
              :label="data[settings.value_key].labels.select_video"
              padding="11px 20px"
              icon="custom-upload"
              icon-position="right"
              @click="showPopup = true"
            />
          </VideoInputPlayer>
        </div>

        <div class="image" v-if="checkType('image')">
          <ImageInput
            @click="showPopup = true"
            :button-label="data[settings.value_key].labels.select_image"
            :image="data[settings.value_key].media"
            :text="data[settings.value_key].title"
            :link="data[settings.value_key].link"
            margin="-25px"
            :is-multiple="false"
            :key="data[settings.value_key]._id"
            :action="false"
          >
            <Button
              class="btn-slateblue"
              :label="data[settings.value_key].labels.select_image"
              padding="11px 20px"
              icon="custom-upload"
              icon-position="right"
              @click="showPopup = true"
            />
          </ImageInput>
        </div>
      </div>
    </template>

    <template slot="footer">
      <div class="x-form__footer">
        <div class="rgba-picker" v-if="checkType('color_field')">
          <FormulateInput
            class="mr-4"
            type="color"
            v-model="color"
            @change="
              dataChanged(
                { text: data[settings.value_key].text, bgColor: hexToRgba },
                'text_editor'
              )
            "
          />
          <FormulateInput
            class="mr-4"
            type="range"
            min="0"
            max="100"
            v-model="alpha"
            @change="
              dataChanged(
                { text: data[settings.value_key].text, bgColor: hexToRgba },
                'text_editor'
              )
            "
          />
          <span>{{ alpha }}%</span>
        </div>
      </div>
    </template>
  </SortableBlock>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import Button from "@/components/Buttons/Button.vue";
import SortableBlock from "@/components/Blocks/SortableBlock";
import VideoInputPlayer from "@/components/Media/VideoInputPlayer";
import ImageInput from "@/components/Media/ImageInput";
import MediaLibrary from "@/components/MediaGallery/MediaLibrary";

import { library } from "@fortawesome/fontawesome-svg-core";
import { customIndentRight, customIndentLeft } from "@/assets/icons";
library.add(customIndentRight, customIndentLeft);

export default {
  name: "XFormSortable",
  components: {
    Button,
    SortableBlock,
    VideoInputPlayer,
    ImageInput,
    MediaLibrary,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    headerActions: {
      type: Array,
      default: () => [],
    },
    sortingData: {
      type: Object,
      default: () => ({ index: 0, amount: 0 }),
    },
    settings: {
      type: Object,
      default: () => {},
    },
    maxIndent: {
      type: Number,
      default: 0,
    },
    updateKey: {
      type: Number,
      default: Date.now(),
    },
    extraKey: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      color: "",
      alpha: "0",
      showPopup: false,
    };
  },
  computed: {
    ...mapState("helper", {
      clipboard: "clipboard",
    }),
    hexToRgba() {
      return (
        "rgba(" +
        parseInt(this.color.slice(-6, -4), 16) +
        "," +
        parseInt(this.color.slice(-4, -2), 16) +
        "," +
        parseInt(this.color.slice(-2), 16) +
        "," +
        this.alpha +
        ")"
      );
    },
    styles() {
      const indent = this.data[this.settings.value_key]?.indent
        ? this.data[this.settings.value_key].indent
        : 0;

      return {
        "--indent": indent * 2 + "rem",
      };
    },
    mediaLibraryType() {
      if (this.data.kind === 3) {
        return "video";
      }

      return "image";
    },
    textEditorValue() {
      if (this.checkType("text_editor")) {
        return this.data[this.settings.value_key].text;
      }

      return "";
    },
  },
  methods: {
    ...mapMutations("_module", {
      updateContentBlock: "updateContentBlock",
    }),
    ...mapMutations("helper", {
      clearClipboard: "clearClipboard",
    }),
    getValue(val) {
      return { val: val };
    },
    pastDefinition() {
      let data = {
        index: this.sortingData.index,
        data: this.clipboard,
      };

      this.$emit("changed", data);
      this.clearClipboard();
    },
    addVideo(files, filesType) {
      this.$emit("addVideo", files, filesType, this.sortingData.index);
    },
    addImage(files, filesType) {
      this.$emit("addImage", files, filesType, this.sortingData.index);
    },
    rgbaToHex() {
      const color = this.data[this.settings.value_key].bgColor;
      const rgba = color.replace(/^rgba?\(|\s+|\)$/g, "").split(",");

      return `#${(
        (1 << 24) +
        (parseInt(rgba[0]) << 16) +
        (parseInt(rgba[1]) << 8) +
        parseInt(rgba[2])
      )
        .toString(16)
        .slice(1)}`;
    },
    rgbaToAlpha() {
      const color = this.data[this.settings.value_key].bgColor;
      const rgba = color.replace(/^rgba?\(|\s+|\)$/g, "").split(",");

      return rgba[3];
    },
    checkType(key) {
      if (
        this.settings &&
        this.settings[key] &&
        this.settings[key].type !== undefined &&
        this.settings.type_key &&
        this.data[this.settings.type_key] === this.settings[key].type
      ) {
        return true;
      }
      return false;
    },
    dataChanged(value, key) {
      let data = {
        index: this.sortingData.index,
        [this.settings.value_key]: value,
        [this.settings.type_key]: this.settings[key].type,
      };
      this.$emit("changed", data);
    },
    generateLink(link) {
      if (!link) return "";
      return `//${window.location.host}${link}`;
    },
    getMedia(value) {
      this.$emit("getMedia", value, this.sortingData.index);
    },
    onClose(status) {
      this.showPopup = status;
    },
  },
  mounted() {
    if (this.checkType("color_field")) {
      this.color = this.rgbaToHex();
      this.alpha = this.rgbaToAlpha();
    }
  },
  watch: {
    "data.color_field.bgColor"() {
      if (this.checkType("color_field")) {
        this.color = this.rgbaToHex();
        this.alpha = this.rgbaToAlpha();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/formulate-input/text";

.x-form {
  margin-left: var(--indent) !important;

  &__header {
    .indent-actions {
      display: flex;
      svg {
        cursor: pointer;
        color: $light_text;
        font-size: 1.5rem;
      }
    }

    &__actions {
      display: flex;

      .icon-wrapper {
        &:not(:last-child) {
          border-right: 1px solid $light_gray;
        }

        .icon {
          padding: 0 10px;
          cursor: pointer;
          transition: all 0.3s ease 0s;

          &:hover {
            opacity: 0.7;
          }

          svg {
            color: $light_text;

            &.chevron-up,
            &.chevron-down {
              font-size: 0.7rem;
            }
          }
        }
      }
    }
  }

  &__content {
    .definition {
      .value {
        position: relative;

        .times-circle {
          position: absolute;
          right: 0.75rem;
          top: 50%;
          transform: translateY(-50%);
          font-size: 1.25rem;
          color: $light_text;
          cursor: pointer;
          @include transition--ease-out;

          &:hover {
            filter: brightness(80%);
          }
        }
      }
    }
  }

  &__footer {
    .rgba-picker {
      display: flex;
      align-items: center;

      * {
        margin-bottom: 0;
      }
    }
  }
}

.video {
  ::v-deep {
    .base-card {
      box-shadow: unset;
      &:hover {
        box-shadow: unset;
      }
    }
  }
}
</style>
