<template>
	<BaseCard class="sortable-block" padding="0">
		<div class="sortable-block__header">
			<div>
				<slot name="header"></slot>
			</div>
			<div class="sortable-block__header__actions">
				<div class="icon-wrapper" v-for="( icon, idx ) in headerActions" :key="idx">
					<div class="icon" v-if="checkIconVisibility(icon.icon)" @click="icon.action(sortingData.index)">
						<font-awesome-icon :class="icon.icon" :icon="icon.icon"/>
					</div>
				</div>
			</div>
		</div>
		<div class="sortable-block__content">
			<slot name="content"></slot>
		</div>
		<div class="sortable-block__footer">
			<slot name="footer"></slot>
		</div>
	</BaseCard>
</template>

<script>
import BaseCard from "@/components/Cards/BaseCard";

import { library } from "@fortawesome/fontawesome-svg-core";
import { customTimesCircle } from "@/assets/icons";
library.add( customTimesCircle );

export default {
	name: 'SortableBlock',
	components: {
		BaseCard,
	},
	props: {
		headerActions: {
			type: Array,
			default: () => []
		},
		sortingData: {
			type: Object,
			default: () => ( {index: 0, amount: 0} )
		},
	},
	methods: {
		checkIconVisibility( icon ) {
			if ( icon === 'chevron-down' && this.sortingData.amount -1 === this.sortingData.index ) return false;
			if ( icon === 'chevron-up' && this.sortingData.index === 0 ) return false;
			return true;
		},
	},
}
</script>

<style lang='scss' scoped>

.sortable-block {

	&__header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 5px 0 5px 15px;
		border-bottom: 1px solid $light_gray;

		&__actions {
			display: flex;

			.icon-wrapper {

				&:not(:last-child) {
					border-right: 1px solid $light_gray;
				}

				.icon {
					padding: 0 10px;
					cursor: pointer;
					transition: all .3s ease 0s;

					&:hover {
						opacity: .7;
					}

					svg {
						color: $light_text;

						&.chevron-up,
						&.chevron-down {
							font-size: .7rem;
						}
					}
				}
			}
		}
	}

	&__content {
		padding: 25px;

		.definition {

			.value {
				position: relative;

				.times-circle {
					position: absolute;
					right: 0.75rem;
					top: 50%;
					transform: translateY( -50% );
					font-size: 1.25rem;
					color: $light_text;
					cursor: pointer;
					@include transition--ease-out;

					&:hover {
						filter: brightness(80%)
					}
				}
			}
		}
	}

	&__footer {
		border-top: 1px solid $light_gray;
		padding: 10px 25px;
	}
}

</style>