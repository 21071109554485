<template>
  <div class="right-sidebar">
    <div class="right-sidebar__close d-lg-none">
      <font-awesome-icon icon="arrow-left" @click="closeSidebar" />
    </div>

    <div class="right-sidebar__list">
      <CollapsibleSearchCard
        :search-placeholder="labels.search"
        :list="definitionList"
        :header-label="labels.definitions"
        :header-btn="createBtn(openCreateDefinitionPopup)"
      />
      <CollapsibleSearchCard
        :search-placeholder="labels.search"
        :list="cardList"
        :header-label="labels.cards"
        :header-btn="createBtn(openCreateCardPopup)"
      />
    </div>
  </div>
</template>

<script>
import CollapsibleSearchCard from "@/components/Collapsible/CollapsibleSearchCard";

import { mapActions, mapMutations } from "vuex";

export default {
  name: "RightSideBar",
  components: {
    CollapsibleSearchCard,
  },
  props: {
    closeSidebar: {
      type: Function,
      default: () => {},
    },
    labels: {
      type: Object,
      default: () => {},
    },
    modulePageData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      page: 1,
      cardList: [],
      definitionList: [],
    };
  },
  computed: {
    stateCardList() {
      return this.$store.state.card.cardList;
    },
  },
  methods: {
    ...mapActions("notification", {
      addNotification: "addNotification",
    }),
    ...mapActions("card", {
      getCards: "getList",
    }),
    ...mapMutations("helper", {
      copyToClipboard: "copyToClipboard",
    }),
    openCreateCardPopup() {
      if (this.$route.name !== "create-card")
        this.$router.push({ name: "create-card" });
    },
    openCreateDefinitionPopup() {
      if (this.$route.name !== "create-definiton")
        this.$router.push({ name: "create-definition" });
    },
    copyToClipboardMt(id) {
      this.stateCardList.forEach((item) => {
        if (item._id === id) {
          this.copyToClipboard(item._id);
          this.addNotification({
            variant: "success",
            msg: this.labels.copying_to_clipboard_successful,
          });
        }
      });
    },
    getCardsMt(page) {
      let data = { page: this.page };
      if (page) data.page = page;

      this.getCards(data).then((res) => {
        if (res.success) {
          this.cardList = [];
          this.definitionList = [];
          let resultList = res.data.data;
          if (resultList.length >= 1) {
            resultList.forEach((item) => {
              if (item.kind === 0)
                this.cardList.push({
                  _id: item._id,
                  label: item.title,
                  actions: [
                    {
                      type: "icon",
                      icon: "custom-external-link",
                      link: { name: "edit-card", params: { id: item._id } },
                    },
                  ],
                });

              if (item.kind === 1)
                this.definitionList.push({
                  _id: item._id,
                  label: item.title,
                  actions: [
                    {
                      type: "button",
                      label: "Copy",
                      variant: "btn-navy",
                      action: this.copyToClipboardMt,
                    },
                    {
                      type: "icon",
                      icon: "custom-external-link",
                      link: {
                        name: "edit-definition",
                        params: { id: item._id },
                      },
                    },
                  ],
                });
            });
          }
        } else if (res.error) {
          this.addNotification({
            variant: "danger",
            msg: res.error,
            labels: this.labels,
          });
        }
      });
    },
    createBtn(action) {
      if (this.modulePageData.hasRole("super") || this.modulePageData.isAuthor)
        return { variant: "btn-purple", label: "New", action: action };

      return {};
    },
  },
  mounted() {
    this.getCardsMt(1);
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
.right-sidebar {
  &__close {
    padding: 15px 25px 15px;
    svg {
      display: block;
      text-align: center;
      margin: 0 0 0 auto;
      padding: 7px;
      width: 40px;
      height: 30px;
      border-radius: 5px;
      background-color: $purple;
      color: $white;
      transition: all 0.3s ease;
      box-shadow: none;
      cursor: pointer;

      &:hover {
        box-shadow: 0 0 6px 2px rgba(93, 52, 206, 0.5);
      }
    }
  }

  &__list {
    overflow-y: auto;
    @include transition--ease-out;
    height: 100%;

    @media (max-width: 992px) {
      height: calc(100% - 60px);
    }

    &::-webkit-scrollbar {
      width: $scrollbar_width;
    }

    &::-webkit-scrollbar-track {
      background: $light_gray;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $navy;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      border-radius: 5px;
    }
  }
}
</style>
