<template>
    <BaseCard 
        class="review mb-4 d-flex align-items-center justify-content-between" 
        padding="20px" 
        :styles="{backgroundColor: backgroundColor}"
    >
        <div class="review__text">{{ text }}</div>
        <div class="review__buttons d-flex flex-column flex-sm-row">
            <Button variant="btn-purple" :label="buttonLabels.notes" @click="reviewNotes" />
        </div>
    </BaseCard>
</template>

<script>
import BaseCard from "@/components/Cards/BaseCard";
import Button from '@/components/Buttons/Button.vue';

export default {
    name: 'ReviewInProgressBlock',
    components: {
        BaseCard,
        Button,
    },
    props: {
        text: {
			type: String,
			default: ''
		},
		buttonLabels: {
			type: Object,
			default: () => {}
		},
        backgroundColor: {
			type: String,
			default: ''
		},
        reviewNotes:{
            type:Function
        }
	},
    methods: {
    }
}
</script>

<style lang="scss" scoped>
    .review {
        &__text {
            flex: 1;
            text-align: center;
        }
    }
</style>